"use strict";
/**
 * htmlエンティティとURLエンコード
 * @module htmlSpecialChars
 * @param {string} string 変換する文字列
 * @return {string} - 変換した文字列
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.htmlSpecialChars = void 0;
var htmlSpecialChars = function (string) {
    return encodeURI(string
        .replace(/&/gu, '&amp;')
        .replace(/</gu, '&lt;')
        .replace(/>/gu, '&gt;')
        .replace(/"/gu, '&quot;')
        .replace(/'/gu, '&#39;'));
};
exports.htmlSpecialChars = htmlSpecialChars;
